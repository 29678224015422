header {
    z-index:0;
    margin: 0;
    padding: 0;
    background-color: black;
    width: 100vw;
    overflow: hidden;
}
#headerVideo{
    width:100vw;
}
/*header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}*/

.overlay {
    position: absolute;
    top:0;
    left:0;
    z-index: 2;
}

@media (orientation: landscape) {
    header, video, .overlay {
        width: 100vw;
    }
}

@media (orientation: portrait) {
    header, video, .overlay {
        width: 100vw;
    }
}
@media (max-width: 20px) {
    header {
        background: url('./img/default.jpg');
    }
    header video {
        display: none;
    }
}