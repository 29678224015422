.myCard{

}
#carrousel-page0{
    background-image: url('img/18707.jpg');
}
#carrousel-page1{
    background-image: url('img/client.jpg');
}
#carrousel-page2{
    background-image: url('img/security.jpg');
}
/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
    margin-bottom: 4rem;
}
/* Declare heights because of positioning of img element */
.carousel-item {
    background-color: rgb(35,35,45);
    background-size: 100% 100%;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
    top: 0rem;
    padding-left: 2%;
    z-index: 10;
    background-color: rgba(80,80,80,0.5);
}
.carousel-caption>ul>li, h2{
    margin-bottom: 5%;
}
.carousel-inner{
    margin: 0;
    padding: 0;
}
.carousel-indicators > li{
    width: 100px;
    border: 30px solid white;
    padding-top: 0;
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (max-width: 1023px) {
    .carousel-item {
        height:30rem;
    }
    .carousel-caption {
        height: 25rem;
    }
    .carousel-indicators > li{
        width: 50px;
        border: 15px solid white;
        padding-top: 0;
    }
    .myCard{
        height: auto;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon{
        width: 50px;
        height: 50px;
    }
}
@media (min-width: 1024px) and (max-width:1679px) {
    .carousel-item {
        height:50rem;
    }
    .carousel-caption {
        height: 35rem;
    }
    .carousel-indicators > li{
        width: 75px;
        border: 20px solid white;
        padding-top: 0;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon{
        width: 75px;
        height: 75px;
    }
}
@media (min-width: 1680px) and (max-width:2559px) {
    .carousel-item {
        height:60rem;
    }
    .carousel-caption {
        height: 40rem;
    }
    .carousel-indicators > li{
        width: 100px;
        border: 30px solid white;
        padding-top: 0;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon{
        width: 100px;
        height: 100px;
    }
}
@media (min-width: 2560px) and (max-width:4095px) {
    .carousel-item {
        height:100rem;
    }
    .carousel-caption {
        height: 70rem;
    }
    .carousel-indicators > li{
        width: 100px;
        border: 30px solid white;
        padding-top: 0;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon{
        width: 100px;
        height: 100px;
    }
}
@media (min-width: 4095px) {
    .carousel-item {
        height:140rem;
    }
    .carousel-caption {
        height: 100rem;
    }
    .carousel-indicators > li{
        width: 200px;
        border: 50px solid white;
        padding-top: 0;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon{
        width: 150px;
        height: 150px;
    }
}

