nav{
    background-color: rgba(0,0,0,.6);
    max-width: 100vw !important;
    margin:0;
    padding:0;
}
.navbar-collapse.show{
    background-color: rgba(0,0,0,0.4) !important;
}
#logoMMS > img{
    margin:0;
    padding:0;
}
.navbar-toggler-icon{
    background-image: url("./img/menu.svg");
}
.dropdown-menu.show{
    background-color: rgba(0,0,0,0.6) !important;
}
.dropdown-item {
    width: 20vw;
    padding:0;
    margin:0;
}
@media (orientation: landscape) {
    .dropdown-item {
        width: 40vw;
    }
}
@media (orientation: portrait) {
    .dropdown-item {
        width: 80vw;
    }
}

@media (max-width: 1023px) {
    main{
        padding-top: 40px;
    }
    nav, .dropdown-item, .nav-link {
        height: 40px;
    }
    #logoMMS{
        height:40px;
    }
    #logoMMS > img{
        height:45px;
    }
}
@media (min-width: 1023px) and (max-width:1679px) {
    main{
        padding-top: 60px;
    }
    nav, .dropdown-item, .nav-link {
        height: 60px;
    }
    #logoMMS{
        height:60px;
    }
    #logoMMS > img{
        height: 70px;
    }
}
@media (min-width: 1679px) and (max-width:2559px) {
    main{
        padding-top: 100px;
    }
    nav, .dropdown-item, .nav-link {
        height: 100px;
    }
    #logoMMS{
        height:100px;
    }
    #logoMMS > img{
        height:120px;
    }
}
@media (min-width: 2559px) and (max-width:4095px) {
    main{
        padding-top: 120px;
    }
    nav, .dropdown-item, .nav-link {
        height: 120px;
    }
    #logoMMS{
        height:120px;
    }
    #logoMMS > img{
        height:140px;
    }
}
@media (min-width: 4095px) {
    main{
        padding-top: 200px;
    }
    nav, .dropdown-item, .nav-link {
        height: 200px;
    }
    #logoMMS{
        height:200px;
    }
    #logoMMS > img{
        height:200px;
    }
}

